<template>
    <div class="logo_pic">
        <img src="../../static/威芯星logo-3.png" width="100">
    </div>
</template>

<style>
.logo_pic{
    width: 50%;
    height: 10%;
    align-items: self-start;
}
</style>