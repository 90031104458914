<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
 <!-- <WssEol></WssEol> -->
 <LogoPic/>
 <WssEol/>
 <FootInfo/>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import WssEol from './components/WSS_EOL.vue'
import LogoPic from './components/WSS_Logo.vue'
import FootInfo from './components/WSS_Foot.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    WssEol,
    LogoPic,
    FootInfo
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1c3f63;
  margin-top: 10px;
}
</style>
