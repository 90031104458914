<template>
    <!-- <div class="title_cn" :href='"https://beian.miit.gov.cn/#/Integrated/index"'>
        <h5>{{msg}}</h5>
    </div> -->
    <a class="link" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener">
      <h5>{{icpcode}}</h5>
      <div class="polic">
        <img src="../../static/beian.ef881569.png" width="25" height="25"/>
        <h5>{{policode}}</h5>
      </div>
    </a>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {},
  data () {
    return {
      msg: '苏ICP备2022032498号 苏公网安备 32059002004109号',
      icpcode: '苏ICP备2022032498号',
      policode: '苏公网安备 32059002004109号',
    }
  }
}
</script>

<style>
.link{
  width: 400px;
  display: flex;
  align-items: center;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.polic{
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>