<template>
    <div class="title_cn">
        <h1>{{msg}}</h1>
        <h4 class="en">{{msg_en}}</h4>
        <h3>{{text_line1}}</h3>
        <h4 class="en">{{text_en1}}</h4>
        <h3>{{text_line2}}</h3>
        <h4 class="en">{{text_en2}}</h4>
        <h3>{{text_line3}}</h3>
        <h4 class="en">{{text_en3}}</h4>
        <h3>{{text_line4}}</h3>
        <h4 class="en">{{text_en4}}</h4>
    </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {},
  data () {
    return {
      msg: '星光虽已逝去，曙光依旧相伴',
      msg_en: 'We would alway be your side ',
      text_line1: '由于公司业务调整，我们将关闭威芯星',
      text_en1: 'Due to business changed, we will shut down WinStar-Smart',
      text_line2: '带给您的不便我们深表歉意',
      text_en2: 'We are so sorry for any inconvenience caused to you',
      text_line3: '我们仍将在集团层面继续服务我们的合作伙伴',
      text_en3: 'We will continue to support our partners at group platform www.willas-array.com',
      text_line4: '请保持联系...后会有期',
      text_en4: 'Keep in touch...forwards meeting you in future',
    }
  }
}
</script>

<style>
.en{
  position: relative;
  top: -20px;
  font-style: italic;
  color: darkcyan;
}
</style>